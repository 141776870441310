var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns is-marginless is-mobile is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-vcentered is-mobile"},[_c('div',{staticClass:"column pr-0"},[_vm._m(0),_c('span',{staticClass:"has-text-dark"},[_vm._v("Mileage")]),(!_vm.permissions.static)?_c('a',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"icon is-outlined",attrs:{"disabled":!_vm.mileage,"title":"Edit"},on:{"click":function($event){return _vm.openModal('companion/CompanionMileage')}}},[_c('i',{staticClass:"fas fa-pencil is-size-7"})]):_vm._e(),(!_vm.permissions.static)?_c('a',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"icon is-outlined",attrs:{"title":"Use today's estimate"},on:{"click":function($event){return _vm.refresh('mileage')}}},[_c('i',{staticClass:"fal fa-sync is-size-7",class:{
              'fa-spinner fa-pulse has-text-grey': _vm.$wait.is('mileage')
            }})]):_vm._e()]),_c('div',{staticClass:"column has-text-right has-text-dark is-narrow"},[(_vm.mileage)?_c('p',[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.mileage.value))+" "),(_vm.mileage.isEstimated)?_c('span',[_vm._v("(estimated)")]):_vm._e()]):_c('p',[_vm._v(" ... ")])])])]),_c('div',{staticClass:"column is-12 has-background-white-ter"},[_c('div',{staticClass:"columns is-vcentered is-mobile"},[_c('div',{staticClass:"column pr-0"},[_vm._m(1),_c('span',{staticClass:"has-text-dark"},[_vm._v("Valuation date")]),(!_vm.permissions.static && _vm.permissions.valuationDateAdjust)?_c('a',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"icon is-outlined",attrs:{"disabled":!_vm.mileage,"title":"Edit"},on:{"click":function($event){return _vm.openModal('companion/CompanionDate')}}},[_c('i',{staticClass:"fas fa-pencil is-size-7"})]):_vm._e(),(!_vm.permissions.static)?_c('a',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"icon is-outlined",attrs:{"title":"Refresh to today"},on:{"click":function($event){return _vm.refresh('date')}}},[_c('i',{staticClass:"fal fa-sync is-size-7",class:{
              'fa-spinner fa-pulse has-text-grey': _vm.$wait.is('date')
            }})]):_vm._e()]),_c('div',{staticClass:"column is-narrow has-text-right has-text-dark"},[(_vm.date)?_c('p',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.date))+" ")]):_c('p',[_vm._v(" ... ")])])])]),_c('div',{staticClass:"column is-12"},[_c('CompanionCondition')],1),(_vm.uuid && _vm.permissions.valuationCertificate)?_c('div',{staticClass:"column is-12 has-background-white-ter"},[_c('div',{staticClass:"columns is-vcentered is-mobile"},[_vm._m(2),_c('div',{staticClass:"column has-text-right is-narrow"},[_c('button',{staticClass:"button is-small is-rounded themed-button",class:{ 'is-loading': _vm.$wait.is('valuationPdf')},attrs:{"disabled":!_vm.isValuationDataLoaded},on:{"click":_vm.getValuationPDF}},[_c('span',[_vm._v("Download")])])])])]):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-vcentered is-mobile"},[_vm._m(3),_c('div',{staticClass:"column has-text-right is-narrow"},[_c('button',{staticClass:"button is-small is-rounded themed-button",class:{ 'is-loading': _vm.$wait.is('companionPdf') },attrs:{"disabled":!_vm.isCompanionDataLoaded},on:{"click":_vm.getCompanionPDF}},[_c('span',[_vm._v("Download")])])])])]),(_vm.showOptionsButton)?_c('div',{staticClass:"column is-12"},[_c('CompanionOptionsButton')],1):_vm._e(),(!_vm.isIntegration)?_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"is-size-7 has-text-danger"},[_vm._m(4),_c('span',[_vm._v(" Updating the "),(_vm.isInsurer)?_c('span',[_vm._v("date,")]):_vm._e(),_vm._v(" mileage or condition will count as a separate lookup ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-tachometer has-text-light"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-calendar-alt has-text-light"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-certificate has-text-light"})]),_c('span',{staticClass:"has-text-dark"},[_vm._v("Valuation certificate")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-download has-text-light"})]),_c('span',{staticClass:"has-text-dark"},[_vm._v("Companion report")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fal fa-info-circle"})])
}]

export { render, staticRenderFns }